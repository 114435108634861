<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>查看详情</span>
      </div>
    </div>
    <div class="redxsd">
      <div class="ewd"></div>
      <div class="order_box">
        <div class="box_sd">
          {{ name }}

          <span>扣款金额：{{ detaileData.amount }}</span>
        </div>
        <div>当前期数：{{ detaileData.orderNumber }}</div>
        <div>流水号：{{ detaileData.planNo }}</div>
        <div class="zhauns" v-if="detaileData.status == 1">
          扣款状态：<span> 扣款成功</span>
        </div>
        <div class="zhauns" v-if="detaileData.status == 0">
          扣款状态： <span>扣款失败</span>
        </div>
        <div class="zhauns" v-if="detaileData.status == 2">
          扣款状态：<span> 未开始</span>
        </div>
        <div class="zhauns" v-if="detaileData.status == 3">
          扣款状态：<span> 扣款中</span>
        </div>
        <div class="zhauns" v-if="detaileData.status == 4">
          扣款状态：<span> 取消</span>
        </div>
        <div>扣款时间：{{ detaileData.planTime }}</div>
        <div class="zhauns" v-if="detaileData.paymentStatus == 0">
          提现状态：<span> 未提现</span>
        </div>
        <div class="zhauns" v-if="detaileData.paymentStatus == 1">
          提现状态：<span> 已提现</span>
        </div>

        <div v-if="detaileData.status == 0">
          原因：
          <span>{{ detaileData.returnMsg }}</span>
        </div>
        <div class="leixi" v-if="leix == 1">单笔扣款</div>
        <div class="leixi" v-if="leix == 2">分期扣款</div>
      </div>
      <div class="insrf">
        <van-button
          class="xxbtn"
          v-if="detaileData.status == 0 || detaileData.status == 2"
          type="warning"
          @click="outkouk"
          >取消扣款</van-button
        ><van-button
          class="xxbtn"
          v-if="detaileData.status == 0 || detaileData.status == 2"
          type="primary"
          @click="gokouk"
          >发起扣款</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { cancelOrderPlan, executePlan } from "@/request/api";
export default {
  data() {
    return {
      detaileData: {},
      name: "",
      leix: "",
    };
  },
  mounted() {
    const detaileData = this.$route.query.data;
    const name = this.$route.query.name;
    const leix = this.$route.query.leix;

    this.detaileData = detaileData;
    this.name = name;
    this.leix = leix;
    console.log(this.detaileData);
    console.log(this.leix);
  },
  methods: {
    // 取消扣款
    outkouk() {
      let that = this;
      this.$dialog
        .alert({
          title: "取消扣款",
          message: "您确认取消扣款吗？取消后无法恢复",
          beforeClose,
          showConfirmButton: true, //确认按钮
          showCancelButton: true, //取消按钮
        })
        .catch(() => {});
      function beforeClose(action, done) {
        if (action === "confirm") {
          // 确认按钮
          that.quequxiao();
          done();
        } else {
          // 取消按钮
          done();
        }
      }
    },

    quequxiao() {
      cancelOrderPlan({
        orderPlanId: this.detaileData.id,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$notify({ type: "success", message: res.message });
          this.$router.push("/withholdList");
        } else {
          this.$notify({ type: "warning", message: res.message });
          this.$router.push("/withholdList");
        }
      });
    },
    // 发起扣款
    gokouk() {
      let that = this;
      this.$dialog
        .alert({
          title: "确认扣款",
          message: "您确认扣款吗？",
          beforeClose,
          showConfirmButton: true, //确认按钮
          showCancelButton: true, //取消按钮
        })
        .catch(() => {});
      function beforeClose(action, done) {
        if (action === "confirm") {
          // 确认按钮
          that.quekoukuan();
          done();
        } else {
          // 取消按钮
          done();
        }
      }
    },
    quekoukuan() {
      executePlan({
        orderPlanId: this.detaileData.id,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$notify({ type: "success", message: res.message });
          this.$router.push("/withholdList");
        } else {
          this.$notify({ type: "warning", message: res.message });
          this.$router.push("/withholdList");
        }
      });
    },

    goback() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
.redxsd {
  background: #f5f5f5;
  // height: 100vh;
  .ewd {
    width: 100%;
    height: 0.6rem;

    margin-bottom: 1rem;
    background: #f5f5f5;
  }
  .order_box {
    width: 85%;
    margin: 0 auto;
    padding: 1rem;
    font-size: 1rem;
    // border: 0.008rem solid #969799;
    background: #ffffff;
    border-radius: 0.8rem;
    .zhauns {
      span {
        color: red;
      }
    }
    div {
      margin-top: 0.5rem;
    }
    .box_sd {
      font-weight: 550;
      span {
        float: right;
        margin-right: 1rem;
        color: red;
      }
    }
    .leixi {
      margin-top: 2rem;
      text-align: center;
      font-weight: 550;
    }
  }
  .insrf {
    width: 100%;
    margin-top: 1rem;
    .xxbtn {
      width: 100%;

      margin-top: 1rem;
    }
  }
}
</style>